import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/project",
    name: "Projects",
    component: () => import("../views/pages/Projects.vue"),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("../views/pages/Clients.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/pages/Services.vue"),
  },
  {
    path: "/finance",
    name: "Finance",
    component: () => import("../views/pages/Finance.vue"),
  },
  {
    path: "/finance/:id",
    name: "Finance item",
    component: () => import("../views/pages/FinanceItem.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
