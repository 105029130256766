<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Hello, Márton!</h1>
      </div>
      <div class="col-3">
        <div class="white-box">
          <div class="top-item">
            <div class="value">12:34</div>
            <div class="key">Havi munkaidőm</div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="white-box">
          <div class="top-item">
            <div class="value">1</div>
            <div class="key">Futó projektjeim</div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="white-box">
          <div class="top-item">
            <div class="value">
              <div class="col money">
                {{
                  Number.parseInt(data?.money?.hold).toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
            </div>
            <div class="key">Várt bevételem</div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="white-box">
          <div class="top-item">
            <div class="value">
              <div class="col money">
                {{
                  Number.parseInt(data?.money?.balance).toLocaleString("hu-HU", {
                    maximumFractionDigits: 0,
                  })
                }}
                <span class="currency">Ft</span>
              </div>
            </div>
            <div class="key">Számlázható bevételem</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch(process.env.VUE_APP_API + "/home")
        .then((res) => res.json())
        .then((response) => {
          if (response.loginNeed) {
            this.$router.push("/login");
          }
          this.data = response;
        })
        .catch((error) => {
          this.message = error;
        });
    },
  },
};
</script>