<template>
  <Header v-if="currentRouteName !== 'Login'" />
  <div id="main">
    <router-view />
  </div>
</template>
<script>
import Header from "./components/Header";
export default {
  components: { Header },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
