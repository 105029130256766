<template>
  <header>
    <div class="container d-flex">
      <div class="logo">
        IntoMedia CRM
      </div>
      <nav class="flex-1-1-0">
        <router-link to="/">Főoldal</router-link>
        <router-link to="/project">Projektek</router-link>
        <router-link to="/clients">Ügyfelek</router-link>
        <router-link to="/services">Szolgáltatások</router-link>
        <router-link to="/finance">Pénzügyek</router-link>
      </nav>
      <div class="timer">

      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>
